import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { Box, Card, CardHeader, Grid, Stack, Typography } from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
//
import { BaseOptionChart } from "../../charts";
import { alpha, styled } from "@mui/material/styles";
import { shadows } from "@mui/system";

// ----------------------------------------------------------------------

function AppConversionRates({ filterText = "Other practices", ...props }) {
  const chartOptionsComparisons = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => "Number of antibiotics prescribed:",
        },
      },
    },
    colors: ["#26734D", "#cc3311"],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(0) + "%";
      },
      style: {
        fontSize: "20px",
        fontWeight: "bold",
      },
    },
    plotOptions: {
      pie: {
        customScale: 0.7,
        donut: { labels: { show: false } },
        dataLabels: {
          offset: -20,
        },
      },
    },
  });

  const chartOptionsComparisons2 = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => "Average Number of antibiotics prescribed:",
        },
      },
    },
    colors: ["#26734D", "#cc3311"],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(0) + "%";
      },
      style: {
        fontSize: "20px",
        fontWeight: "bold",
      },
    },
    plotOptions: {
      pie: {
        customScale: 0.7,
        donut: { labels: { show: false } },
        dataLabels: {
          offset: -20,
        },
      },
    },
  });

  return (
    <Card sx={{ boxShadow: "none", mt: 4 }}>
      <br />
      <Grid container spacing={3} justifyContent="space-around">
        <Grid item xs={12} md={6} lg={5}>
          <ReactApexChart
            type="pie"
            series={[props.ab.practice_green, props.ab.practice_red]}
            options={chartOptionsComparisons}
            height={300}
          />
          <Typography variant="subtitle2" align="center" color="#22577A">
            Your Practice
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <ReactApexChart
            type="pie"
            series={[props.abcomparison.otherpractice_green, props.abcomparison.otherpractice_red]}
            options={chartOptionsComparisons2}
            height={300}
          />
          <Typography variant="subtitle2" align="center" color="#22577A">
            {filterText}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}

import { connect } from "react-redux";

const mapState = (state) => ({
  ab: state.antibiotics.ab,
  abcomparison: state.antibioticscomparisons.abcomparison,
});

const mapDispatch = ({
  antibioticscomparisons: { getComparisonsAB },
  antibiotics: { getGeneralAB },
}) => ({
  getComparisonsAB: () => getComparisonsAB(),
  getGeneralAB: () => getGeneralAB(),
});

export default connect(mapState, mapDispatch)(AppConversionRates);
