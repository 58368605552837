// material
import { Box, Grid, Container, Typography, Paper, Stack, TextField, Button } from '@mui/material';
// components
import Page from '../components/Page';
import {
  AppLineAB1MonthAudit
} from '../components/_dashboard/audit';
import React, { useState, useEffect } from "react";
import handleDownloadPdf from './exportPdf'
import moment from 'moment';
import { connect } from 'react-redux';
import { checkMonth } from '../utils/formatNumber'
import ReactGA from "react-ga4";
// import { GridPrintExportOptions } from '@mui/x-data-grid';


// ----------------------------------------------------------------------


function DashboardApp({user,  auditDetails:{auditPayload: { tf, goal }}}) {
  const printRef = React.useRef();

  useEffect(() => {
    const eventCategory = "Auditview 3 Goal Page 1 month";
    const eventPage = "Audit 3 Goal Page 1 month";

    ReactGA.send({
      hitType: "event",  // Instead of "pageview", we use "event" for custom tracking
      eventCategory: eventCategory,  // Define your own category
      eventAction: eventCategory,   // Or "pageview2" depending on your needs
      eventLabel: window.location.pathname,  // The current path
      title: eventPage,  // Keep the title if necessary
    });
  }, []);

  return (
    <Page title="Dashboard/Audit" sx={{ backgroundColor: '#fff' }}>
      <Container maxWidth="xl" >
        <div ref={printRef}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={12}>
              <Typography variant="h2" color='secondary'>Audit of Antibiotic Prescribing</Typography>
              <br />
              <br />
              <Typography variant="h3" color='secondary'>Practice: {user?.username}</Typography>
              <Typography variant="h6" color='secondary'>Date: {moment().format('MMMM Do YYYY')}</Typography>
              <br />
              <br />
              <Typography variant="h4" color='secondary'>Goal: Reduce the number of antibiotic prescriptions. </Typography>
              <br />
              <Typography variant="h4" color='secondary'>Timeframe: {checkMonth(tf)}. </Typography>
              <br />
            </Grid>
            <Grid item xs={12} id="duration">
              <AppLineAB1MonthAudit />
            </Grid>
            <Grid item xs={12} md={6} lg={12}>
              <br />
              <br />
              <br />
            </Grid>

          </Grid>
        </div>
        <Button onClick={() => handleDownloadPdf('audit.pdf', printRef)} fullWidth variant="outlined" color='primary'><Typography variant="h4"> Export as PDF</Typography> </Button>

      </Container>
    </Page>
  );
}

const mapState = (state) => ({
  auditDetails: state.audit.auditDetails,
  user: state.user,
});

const mapDispatch = ({ antibiotics: { getGeneralAB } }) => ({
  getGeneralAB: () => getGeneralAB(),
});

export default connect(mapState, mapDispatch)(DashboardApp);