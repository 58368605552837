import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader, Stack, Box, Typography } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------





function AppTypes(props) {
  const CHART_DATA_Types = props.patients.gms_type
  const CHART_DATA_OTHERTypes = props.otherpatients.other_gms_type
  
  const chartOptions = merge(BaseOptionChart(), {
    colors: props.patients.gms_colors,
    labels: props.patients.gms_categories,
    // stroke: { colors: [theme.palette.background.paper] },
    legend: { show: false},
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      theme: 'dark',
      marker: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(0) + "%"
      },
    style: {
      fontSize: '15px',
      fontWeight: 'bold',
  },
          },
    plotOptions: {
      pie: {
      customScale: 1,
      donut: 
        { labels: { show: false },
          // customScale: 0.5
          size: 60 },
          expandOnClick: false,
          },
    chart: {
      brush: {
        enabled: true,
        target: 'TypesBar',
        autoScaleYaxis: false,
        id: 'TypesDonut',
        group: 'Types'
      }},}
  });


  return (
    <Card   sx={{boxShadow: 'none', mt: 4}}>
        <Stack direction="row" justifyContent="space-evenly" alignItems="flex-end" spacing={1} mt={1}>
        <Box sx={{ mx: 1 }} dir="ltr">
        <ReactApexChart type="donut" series={CHART_DATA_Types} options={chartOptions} height={280} />
        <Typography variant="caption" color = 'grey' display="block" align='center' >Your practice</Typography>     
        </Box>
        <Box sx={{ mx: 1 }} dir="ltr">
        <ReactApexChart type="donut" series={CHART_DATA_OTHERTypes} options={chartOptions} height={280} />
        <Typography variant="caption" color = 'grey' display="block" align='center' >Other practices</Typography>     
        </Box>
        </Stack>
    </Card>
  );
}

import { connect } from "react-redux";
const mapState = state => ({
  patients: state.overview.patients,
  otherpatients: state.antibioticscomparisons.patients,

 });
 
 const mapDispatch = ({ 
    overview: {loadData}}) => ({
    loadData: () => loadData()
 });
 
 export default connect(mapState, mapDispatch)(AppTypes);
