import { merge } from 'lodash';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader, Stack, Box, Typography } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------





function AppCurrentVisits(props) {
  const CHART_DATAFemale = props.patients.gender_patients
  const CHART_DATAOtherPractice = props.otherPatients.other_gender_patients
  const chartOptions = merge(BaseOptionChart(), {
    colors: props.patients.gender_colours,
    labels: props.patients.gender_categories,
    // stroke: { colors: [theme.palette.background.paper] },
    legend: { show: false},
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(0) + "%"
      },
    style: {
      fontSize: '15px',
      fontWeight: 'bold',
  },
          },
    plotOptions: {
      pie: {
      customScale: 1,
      donut: 
        { labels: { show: false },
          // customScale: 0.5
          size: 60 },
          expandOnClick: false,
          },
    chart: {
      brush: {
        enabled: true,
        target: 'GenderBar',
        autoScaleYaxis: false,
        id: 'GenderDonut',
        group: 'Gender'
      },
    },}
  });


  return (
    <Card  sx={{boxShadow: 'none', mt: 4}}>
        <Stack direction="row" justifyContent="space-evenly" alignItems="flex-end" spacing={2} mt={1}>    
        <Box sx={{ mx: 1 }} dir="ltr">
        <ReactApexChart type="donut" series={CHART_DATAFemale} options={chartOptions} height={280} />
        <Typography variant="caption" color = 'grey' display="block" align='center' >Your practice</Typography>     
        </Box>
        <Box sx={{ mx: 1 }} dir="ltr">
        <ReactApexChart type="donut" series={CHART_DATAOtherPractice} options={chartOptions} height={280} />
        <Typography variant="caption" color='grey' display="block" align='center' >Other practices</Typography>     
        </Box>
        </Stack>
    </Card>
  );
}

import { connect } from "react-redux";
const mapState = state => ({
  patients: state.overview.patients,
  otherPatients: state.antibioticscomparisons.patients
 });
 
 const mapDispatch = ({ 
  overview: {loadData}}) => ({
    loadData: () => loadData()
 });
 
 export default connect(mapState, mapDispatch)(AppCurrentVisits);
