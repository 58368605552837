import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Box, Card, CardHeader, Stack, Typography } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------


  
function AppPracticePrescriptions2(props) {
  const CHART_DATA_PrescriptionsWeekDay = [{
    data: props.prescriptions.week_days
   },
 ];
  const chartOptions_PrescriptionsWeekDay = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      x: {
          show: true,
          // format: 'dd MMM',
          formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        }
      },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `Percentage of all prescription issued: `
        },
        formatter: function (val, { seriesIndex, w }) {
          const seriesData = w.config.series[seriesIndex].data;
          const total = seriesData.reduce((acc, curr) => acc + curr, 0);
          const percentage = ((val / total) * 100).toFixed(0);
          return  `${percentage}%`;
        }
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    colors: ['#3E8397'],
    plotOptions: {
      bar: 
      {horizontal: false,
      dataLabels: {
      position: 'top'
    },
      columnWidth: '60%',
  },
  },
  dataLabels: {
    formatter: function (val, opt) {
    const label_value = fNumber(val)
    return label_value
  },
    enabled: true,
    style: {
        colors: ['#708090']
    },
    offsetY: -20
  },
    xaxis: {
      categories: [
        'Monday', 
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
      ],
      // position: 'top',
    },
    yaxis: {
      show: false,
      //max: "auto",
      //tickAmount: 6,
    labels: {
      formatter: function (val, opt) {
        const label_value = fNumber(val)
        return label_value
        },
      show: true,
      style: {
        colors: '#808080'
      }
    },
  }
  });



  return (
     <Card sx={{boxShadow: 'none', mt: 4, height: 400}} > 
      <Box sx={{ mt: 4, mx: 5, width:450, height: 300 }} dir="ltr">       
    <ReactApexChart 
    type="bar" 
    series={CHART_DATA_PrescriptionsWeekDay} 
    options={chartOptions_PrescriptionsWeekDay} height={364} />
      </Box>
    </Card>
  );
}


import { connect } from "react-redux";
const mapState = state => ({
  prescriptions: state.overview.prescriptions
});

const mapDispatch = ({ 
  overview: {loadData}}) => ({
   loadData: () => loadData()
});
 
 export default connect(mapState, mapDispatch)(AppPracticePrescriptions2);

