// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { isJwtExpired, timeToExpire } from './components/authentication/checkJWT';
import ReactGA from 'react-ga4';

import SB from './components/authentication/ExpireJWTSnackBar';


// ----------------------------------------------------------------------

const trackingID = 'G-WJYKN4ME0C';
ReactGA.initialize(trackingID);

const app = function App(props) {
  const navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    // Check for stored user data on app initialization
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const userData = JSON.parse(storedUser);
      props.loginUser(userData); // Dispatch login action with stored data
    }
  }, []);

  let checkJWT = (token) => {
    if (token) {
      console.log(timeToExpire(token))
    }
  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Check if user is autnenthicated after refresh
    if(!location.pathname.includes("verify")){
      //props.refreshToken().then((token) => {
        //console.log("navigating to app", token)
        //console.log(location.pathname)
        //navigate('/dashboard/app', { replace: true });})
    }

    
  }, []);

  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <Router />
      <SB/>
    </ThemeConfig>
  );
}


const mapState = state => ({
 
});

const mapDispatch = ({
  user: { loginUser, refreshToken } }) => ({
    refreshToken: () => refreshToken(),
    loginUser: (payload) => loginUser(payload)
  });

export default connect(mapState, mapDispatch)(app);
