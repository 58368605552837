import React, { useState, useEffect } from "react";
import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { useTheme, styled } from "@mui/material/styles";
import { Card, CardHeader, Stack, Box, alpha } from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
//
import { BaseOptionChart } from "../../charts";

// ----------------------------------------------------------------------

// const allCategories = [
//   "<1", "1-6", "7-17", "18-29", "30-49", "50-69", ">70"
// ];

function AppAgesBar(props) {
  const [prescXaxis, setPrescXaxis] = useState(0);

  // Create state variables to manage categories for each age group
  // const [categoriesAges17, setCategoriesAges17] = useState(["<1", "1-6", "7-17"]);
  // const [categoriesAges49, setCategoriesAges49] = useState(["18-29", "30-49"]);
  // const [categoriesAges69, setCategoriesAges69] = useState(["50-69"]);
  // const [categoriesAges70, setCategoriesAges70] = useState([">70"]);

  // // Define a function to update the categories based on data changes
  // const updateCategories = () => {
  //   // Update categories for each age group
  //   setCategoriesAges17(["<1", "1-6", "7-17"]);
  //   setCategoriesAges49(["18-29", "30-49"]);
  //   setCategoriesAges69(["50-69"]);
  //   setCategoriesAges70([">70"]);
  // };

  // Use a useEffect to call the updateCategories function when data changes
  // useEffect(() => {
  //   updateCategories();
  // }, [props.patients.age, props.otherpatients.other_age]);

  const CHART_DATAAges17 = [
    {
      name: ["Your practice"],
      data: props.patients.age,
    },
    {
      name: ["Other practices"],
      data: props.otherpatients.other_age,
    },
  ];

  useEffect(() => {
    const data1 = props.patients.age;
    const data2 = props.otherpatients.other_age;
    const maxY1 = Math.max(...data1.map((item) => item));
    const maxY2 = Math.max(...data2.map((item) => item));
    const grandMax = Math.max(maxY1, maxY2);
    const maxX = grandMax + grandMax * 0.2;
    setPrescXaxis(maxX);
  }, [props.patients.age, props.otherpatients.other_age]);
  //  const CHART_DATAAges17 = [{
  //   name: ['Your practice'],
  //   data: props.patients.age.slice(0, 3),
  //   },{
  //   name: ['Other practices'],
  //   data: props.otherpatients.other_age.slice(0, 3),
  //   },
  // ];

  // const CHART_DATAAges49 = [{
  //   name: ['Your practice'],
  //   data: props.patients.age.slice(3, 5),
  //   },{
  //   name: ['Other practices'],
  //   data: props.otherpatients.other_age.slice(3, 5),
  //   },
  // ]

  // const CHART_DATAAges69 = [{
  //   name: ['Your practice'],
  //   data: [props.patients.age[5]]
  //   },{
  //   name: ['Other practices'],
  //   data: [props.otherpatients.other_age[5]]
  //   },
  // ]

  // const CHART_DATAAges70 = [{
  //   name: ['Your practice'],
  //   data: [props.patients.age[6]]
  //   },{
  //   name: ['Other practices'],
  //   data: [props.otherpatients.other_age[6]]
  //   },
  // ]

  const chartOptions_AgesBar = merge(BaseOptionChart(), {
    tooltip: {
      enabled: false,
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: undefined, // Set formatter to undefined or an empty string
        title: {
          show: false,
        },
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: props.patients.age_categories,
    },

    colors: [
      function ({ value, seriesIndex, dataPointIndex, w }) {
        if (dataPointIndex < 3) {
          return "#3E8397";
        } else if (dataPointIndex < 5) {
          return "#5ca8bc";
        } else if (dataPointIndex < 6) {
          return "#92c5d3";
        } else {
          return "#c9e2e9";
        }
      },
      function ({ value, seriesIndex, dataPointIndex, w }) {
        if (dataPointIndex < 3) {
          return "rgba(62,	131,	151, 0.5)";
        } else if (dataPointIndex < 5) {
          return "rgba(92,	168,	188, 0.5)";
        } else if (dataPointIndex < 6) {
          return "rgba(146,	197,	211, 0.5)";
        } else {
          return "rgba(201,	226	,233, 0.5)";
        }
      },
    ],

    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
        columnWidth: "60%",
        distributed: false,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return fNumber(val);
      },
      style: {
        colors: ["#708090"],
      },
      offsetY: -20,
    },
    yaxis: {
      show: false,
      max: prescXaxis,
      //max: 'auto',
    },
    stroke: {
      show: true,
      width: 4,
      colors: ["transparent"],
    },
  });

  // const chartOptions_AgesBar49 = merge(BaseOptionChart(), {
  //      tooltip: {
  //       marker: { show: false },
  //       x: {
  //           show: true,
  //           // format: 'dd MMM',
  //           formatter: undefined,
  //         title: {
  //                 formatter: (seriesName) => seriesName,
  //         }
  //       },
  //       y: {
  //             formatter: undefined, // Set formatter to undefined or an empty string
  //             title: {
  //                 show: false
  //             },
  //         },
  //     },
  //     grid: {
  //       borderColor: "transparent",
  //       row: {
  //         colors: ["transparent"], // takes an array which will be repeated on columns
  //         opacity: 0.5,
  //       },
  //     },
  //     xaxis: {
  //       categories: categoriesAges49, // Use the specific categories for this chart
  //     },
  //     colors: ["#5ca8bc",alpha("#5ca8bc", 0.5)],

  //     plotOptions: {
  //       bar:
  //       {horizontal: false,
  //       dataLabels: {
  //       position: 'top'
  //     },
  //       columnWidth: '60%',
  //       distributed: false,
  //   },
  //   },
  //   legend: {
  //     show: false
  //   },
  //   dataLabels: {
  //     enabled: true,
  //     style: {
  //         colors: ['#708090']
  //     },
  //     offsetY: -20
  //   },
  //     yaxis: {
  //       show: false,
  //       max: 2000,
  //     },
  // stroke: {
  //                 show: true,
  //                 width: 6,
  //                 colors: ['transparent']
  //               },
  //   });

  // const chartOptions_AgesBar69 = merge(BaseOptionChart(), {
  //      tooltip: {
  //       marker: { show: false },
  //       x: {
  //           show: true,
  //           // format: 'dd MMM',
  //           formatter: undefined,
  //         title: {
  //                 formatter: (seriesName) => seriesName,
  //         }
  //       },
  //       y: {
  //             formatter: undefined, // Set formatter to undefined or an empty string
  //             title: {
  //                 show: false
  //             },
  //         },
  //     },
  //     grid: {
  //       borderColor: "transparent",
  //       row: {
  //         colors: ["transparent"], // takes an array which will be repeated on columns
  //         opacity: 0.5,
  //       },
  //     },
  //     xaxis: {
  //       categories: categoriesAges69, // Use the specific categories for this chart
  //     },
  //     colors: ["#92c5d3",alpha("#92c5d3", 0.5)],

  //     plotOptions: {
  //       bar:
  //       {horizontal: false,
  //       dataLabels: {
  //       position: 'top'
  //     },
  //       columnWidth: '60%',
  //       distributed: false,
  //   },
  //   },
  //   legend: {
  //     show: false
  //   },
  //   dataLabels: {
  //     enabled: true,
  //     style: {
  //         colors: ['#708090']
  //     },
  //     offsetY: -20
  //   },
  //     yaxis: {
  //       show: false,
  //       max: 2000,
  //     },
  // stroke: {
  //                 show: true,
  //                 width: 6,
  //                 colors: ['transparent']
  //               },
  //   });

  // const chartOptions_AgesBar70 = merge(BaseOptionChart(), {
  //     tooltip: {
  //       marker: { show: false },
  //       x: {
  //           show: true,
  //           // format: 'dd MMM',
  //           formatter: undefined,
  //         title: {
  //                 formatter: (seriesName) => seriesName,
  //         }
  //       },
  //       y: {
  //             formatter: undefined, // Set formatter to undefined or an empty string
  //             title: {
  //                 show: false
  //             },
  //         },
  //     },
  //     grid: {
  //       borderColor: "transparent",
  //       row: {
  //         colors: ["transparent"], // takes an array which will be repeated on columns
  //         opacity: 0.5,
  //       },
  //     },
  //     xaxis: {
  //       categories: categoriesAges70, // Use the specific categories for this chart
  //     },
  //     colors: ["#c9e2e9",alpha("#c9e2e9", 0.5)],

  //     plotOptions: {
  //       bar:
  //       {horizontal: false,
  //       dataLabels: {
  //       position: 'top'
  //     },
  //       columnWidth: '60%',
  //       distributed: false,
  //   },
  //   },
  //   legend: {
  //     show: false
  //   },
  //   dataLabels: {
  //     enabled: true,
  //     style: {
  //         colors: ['#708090']
  //     },
  //     offsetY: -20
  //   },
  //     yaxis: {
  //       show: false,
  //       max: 2000,
  //     },
  // stroke: {
  //                 show: true,
  //                 width: 6,
  //                 colors: ['transparent']
  //               },
  //   });

  return (
    <Card sx={{ boxShadow: "none", mt: 4 }}>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-end"
        spacing={0}
        mt={1}
      >
        <Box dir="ltr" width="100%">
          <ReactApexChart
            type="bar"
            series={CHART_DATAAges17}
            options={chartOptions_AgesBar}
            height={280}
          />
        </Box>
      </Stack>
    </Card>
  );
}
// <Box dir="ltr" width='30%'>
// <ReactApexChart type="bar" series={CHART_DATAAges49} options={chartOptions_AgesBar49} height={280} />
// </Box>
// <Box dir="ltr" width='15%'>
// <ReactApexChart type="bar" series={CHART_DATAAges69} options={chartOptions_AgesBar69} height={280} />
// </Box>
// <Box dir="ltr" width='15%'>
// <ReactApexChart type="bar" series={CHART_DATAAges70} options={chartOptions_AgesBar70} height={280} />
// </Box>

import { connect } from "react-redux";
const mapState = (state) => ({
  patients: state.overview.patients,
  otherpatients: state.antibioticscomparisons.patients,
});

const mapDispatch = ({
  overview: { loadData },
  antibioticscomparisons: { getComparisonsAB },
}) => ({
  loadData: () => loadData(),
  getComparisonsAB: () => getComparisonsAB(),
});

export default connect(mapState, mapDispatch)(AppAgesBar);
