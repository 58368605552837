import { merge } from "lodash";
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Box, Card, CardHeader, Grid, Stack, Typography } from "@mui/material";
import { fNumber } from "../../../utils/formatNumber";
import { BaseOptionChart } from "../../charts";
import { alpha, styled } from "@mui/material/styles";
import { shadows } from "@mui/system";

function AppConversionRates(props) {
  const [refreshGraph, setRefreshGraph] = useState(false);
  const [types_prescribed_ordered, setTypes_prescribed_ordered] = useState([]);
  const [prescribedTranformData, setPrescribedTranformData] = useState([]);
  const [resistantTranformData, setResistantTranformData] = useState([]);
  const [prescXaxis, setPrescXaxis] = useState(0);
  const [chartOptionsTypes, setChartOptionsTypes] = useState({
    tooltip: {
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        //formatter: (seriesName) => 'TOTAL'/100*fNumber(seriesName),
        formatter: function (value, opts) {
          // const sum = opts.series[0].reduce((a, b) => a + b, 0);
          // const percent = (value / sum) * 100;
          //const percent = (value / props.ab.total_12_month)*100;
          return Math.round(value) + "%"; //percent.toFixed(0) + '%'
        },
        title: {
          formatter: (seriesName) => `Percentage of total: `,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      formatter: function (val, opt) {
        const label_value = fNumber((val / 100) * props.ab.total_12_month);
        return label_value;
      },
      enabled: true,
      offsetX: 100,
      style: {
        colors: ["black"],
        fontWeight: "normal",
      },
    },
    xaxis: {
      min: 0,
      max: 100,
      tickAmount: 4,
      title: {
        text: "%",
      },
      labels: {
        show: true,
        style: {
          colors: "#22577A",
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: "#22577A",
        },
      },
    },
    chart: {
      id: "ABBreakdown",
    },
  });

  useEffect(() => {
    if (types_prescribed_ordered && types_prescribed_ordered !== undefined) {
      // If data has changed, trigger graph refresh
      setRefreshGraph((prev) => !prev);
    }
  }, [types_prescribed_ordered]);

  useEffect(() => {
    if (props.ab.types_prescribed && props.ab.types_prescribed !== undefined) {
      setChartOptionsTypes((prevOptions) => ({
        ...prevOptions,
        dataLabels: {
          ...prevOptions.dataLabels,
          formatter: (val) => {
            const label_value = fNumber((val / 100) * props.ab.total_12_month);
            return label_value;
          },
        },
      }));
      setTypes_prescribed_ordered(
        props.ab.types_prescribed.sort((a, b) => b.y - a.y)
      );
      setRefreshGraph((prev) => !prev);
    }
  }, [props.ab.types_prescribed]);

  useEffect(() => {
    if (props.ab.types_prescribed && props.ab.total_12_month) {
      // const newData = props.ab.types_prescribed.map((item) => {
      //   const newY = (item.y / 100) * props.ab.total_12_month;
      //   return { ...item, y: newY };
      // });

      const newData = props.ab.types_prescribed.map((item) => {
        const newY = (item.y / 100) * props.ab.total_12_month;

        // If the value is less than 5, set `y` to 0 and indicate it's less than 5 in `label`.
        if (newY < 5) {
          return { ...item, y: 0, label: 'Count less than 5' };
        }

        return { ...item, y: newY };
      });

      setPrescribedTranformData(newData);

      const prescValue = newData.map((item) => item.y);
      const maxVal = Math.max(...prescValue) * 1.3;
      const maxX = Math.ceil(maxVal / 1000) * 1000;

      setPrescXaxis(maxX);
    }
  }, [props.ab.types_prescribed, props.ab.total_12_month]);

  useEffect(() => {
    if (props.ab.types_resistance) {

      const newData = props.ab.types_resistance.map((item) => {

        const newY = ((item.y * props.ab.resistanceTotal) / 100).toFixed(0);

        // If the value is less than 5, set `y` to 0 and indicate it's less than 5 in `label`.
        // if (newY < 5) {
        //   return { ...item, y: 0, label: 'Count less than 5' };
        // }

        return { ...item };
      });

      setResistantTranformData(newData);
    }
  }, [props.ab.types_resistance]);

  const chartOptionsPrescribe = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        // formatter: function (value, opts) {
        //   const percentage = (value / props.ab.total_12_month) * 100;
        //   return Math.round(percentage) + "%";
        // },
        formatter: function (value, opts) {
          const item = prescribedTranformData[opts.dataPointIndex];
          if (item.label === 'Count less than 5') {
            return item.label;
          }
          const percentage = (value / props.ab.total_12_month) * 100;
          return Math.round(percentage) + "%";
        },

        title: {
          formatter: (seriesName) => `Percentage of total: `,
        },
      },
    },
    chart: {
      type: "bar",
      height: 350,
      stacked: false,
      stackType: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
        barHeight: 20,
        borderRadius: 5,
        borderRadiusApplication: "end",
      },
    },
    dataLabels: {
      // formatter: function (val, opt) {
      //   return fNumber(val);
      // },
      formatter: function (val, opt) {
        const item = prescribedTranformData[opt.dataPointIndex];
        return item.label === 'Count less than 5' ? item.label : fNumber(val);
      },
      enabled: true,
      offsetX: 45,
      style: {
        colors: ["#708090"],
        fontWeight: "normal",
      },
    },
    xaxis: {
      min: 0,
      max: prescXaxis,
      forceNiceScale: true,
      tickAmount: 5,
      title: {
        style: {
          color: "#22577A",
        },
      },
      labels: {
        show: true,
        style: {
          colors: "#22577A",
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        maxWidth: 100,
        style: {
          colors: "#22577A",
        },
      },
    },
  });

  const chartOptionsComparisons = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: function (value, opts) {
          return fNumber(value) + "";
        },
        title: {
          formatter: (seriesName) => "Total number of antibiotics prescribed:",
        },
      },
    },
    colors: ["#26734D", "#cc3311"],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(0) + "%";
      },
      style: {
        fontSize: "20px",
        fontWeight: "bold",
      },
    },
    plotOptions: {
      pie: {
        customScale: 0.7,
        donut: { labels: { show: false } },
        dataLabels: {
          offset: -20,
        },
      },
    },
  });

  const chartOptionsComparisons2 = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: function (value, opts) {
          return fNumber(value) + "";
        },
        title: {
          formatter: (seriesName) => "Average number of antibiotics prescribed:",
        },
      },
    },
    colors: ["#26734D", "#cc3311"],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(0) + "%";
      },
      style: {
        fontSize: "20px",
        fontWeight: "bold",
      },
    },
    plotOptions: {
      pie: {
        customScale: 0.7,
        donut: { labels: { show: false } },
        dataLabels: {
          offset: -20,
        },
      },
    },
  });

  const CHART_DATA_Resist = [
    {
      data: [
        {
          x: "Amoxicillin",
          y: 75,
          fillColor: "#26734D",
          strokeColor: "#26734D",
        },
        {
          x: "Doxycycline",
          y: 5,
          fillColor: "#26734D",
          strokeColor: "#26734D",
        },
        {
          x: "Ciprofloxacin",
          y: 85,
          fillColor: "#cc3311",
          strokeColor: "#cc3311",
        },
        {
          x: "Penicillin V",
          y: 23,
          fillColor: "#26734D",
          strokeColor: "#26734D",
        },
        {
          x: "Azithromyzin",
          y: 79,
          fillColor: "#cc3311",
          strokeColor: "#cc3311",
        },
        {
          x: "Co-amoxiclav",
          y: 100,
          fillColor: "#cc3311",
          strokeColor: "#cc3311",
        },
        {
          x: "Cephalexin",
          y: 45,
          fillColor: "#26734D",
          strokeColor: "#26734D",
        },
      ],
    },
  ];

  const chartOptionsResist = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: (value,opts) => {
          if (props.ab.denominator) {
            const item = resistantTranformData[opts.dataPointIndex];
            if (item.label === 'Count less than 5') {
              return item.label;
            }
            const actualCount = ((value * props.ab.denominator) / 100).toFixed(0);
            return fNumber(actualCount)
          }
        },
        title: {
          formatter: (seriesName) =>
            `Count of isolates showing resistance: `,
        },
      },
    },
    chart: {
      type: "bar",
      height: 350,
      stacked: false,
      stackType: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
        barHeight: 20,
        borderRadiusApplication: "end",
      },
    },
    dataLabels: {
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        const item = resistantTranformData[dataPointIndex];
        return item.label === 'Count less than 5' ? item.label : (value ? value.toFixed(0) : 0) + "%";
      },
      enabled: true,
      offsetX: 40,
      style: {
        colors: ["#708090"],
        fontWeight: "normal",
      },
      // offsetX: 50,
    },
    xaxis: {
      min: 0,
      max: 100,
      stepSize: 25,
      title: {
        text: "%",
        style: {
          color: "#22577A",
        },
      },
      labels: {
        show: true,
        style: {
          colors: "#22577A",
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: "#22577A",
        },
      },
    },
  });

  return (
    <Card sx={{ boxShadow: "none" }}>
      <br />
      <br />
      <br />
      <Typography variant="h4" color="#22577A">
        Antibiotic Prescriptions
      </Typography>
      <Typography
        variant="subtitle3"
        color="#26734D"
        sx={{ fontWeight: "bold" }}
      >
        Green Antibiotics{" "}
      </Typography>
      <Typography variant="subtitle3" color="#22577a">
        are more effective, have fewer side effects and are less likely to lead
        to resistant infections.
      </Typography>
      <br />
      <Typography
        variant="subtitle3"
        color="#cc3311"
        sx={{ fontWeight: "bold" }}
      >
        Red Antibiotics{" "}
      </Typography>
      <Typography variant="subtitle3" color="#22577a">
        should not be used in general practice unless absolutely necessary.
      </Typography>
      <br />
      <br />
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <ReactApexChart
            type="pie"
            series={[props.ab.practice_green, props.ab.practice_red]}
            options={chartOptionsComparisons}
            height={300}
            key={refreshGraph}
          />
          <Typography variant="subtitle2" align="center" color="#22577A">
            Your practice
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <ReactApexChart
            type="pie"
            series={[props.ab.otherpractice_green, props.ab.otherpractice_red]}
            options={chartOptionsComparisons2}
            height={300}
            key={refreshGraph}
          />
          <Typography variant="subtitle2" align="center" color="#22577A">
            All practices average
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="h6" mt={16} color="#22577A">
        {" "}
        Breakdown of antibiotic prescriptions and resistance in your practice
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-end"
        spacing={6}
        mt={1}
      >
        <Grid container>
          <Grid item sm={12} md={6}>
            <Box sx={{ mx: 1, width: 500, height: 400 }} dir="ltr">
              <br />
              <Typography variant="subtitle3" color="#22577A">
                {" "}
                Total number prescribed for selected period:
              </Typography>{" "}
              <Typography
                variant="subtitle3"
                color="#AA3377"
                sx={{ fontWeight: "bold" }}
              >
                {fNumber(props.ab.total_12_month)}
              </Typography>
              <ReactApexChart
                type="bar"
                series={[
                  { data: prescribedTranformData.sort((a, b) => b.y - a.y) },
                ]}
                //series={[props.ab.types_prescribed]}
                options={chartOptionsPrescribe}
                key={refreshGraph}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box
              sx={{ mx: 1, my: 3, width: 500, height: 400 }}
              dir="ltr"
              id="resistance"
            >
              <Typography variant="subtitle3" color="#22577a">
                {" "}
                % resistance in isolates from urine samples to at least one
                antibiotic.{" "}
              </Typography>
              <ReactApexChart
                type="bar"
                series={[
                  { data: resistantTranformData.sort((a, b) => b.y - a.y) },
                ]}
                options={chartOptionsResist}
                key={refreshGraph}
              />
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
}

import { connect } from "react-redux";
import { debug } from "logrocket";
const mapState = (state) => ({
  ab: state.antibiotics.ab,
});

const mapDispatch = ({ antibiotics: { getGeneralAB } }) => ({
  getGeneralAB: () => getGeneralAB(),
});

export default connect(mapState, mapDispatch)(AppConversionRates);
