import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader, Stack, Box, alpha, Typography } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------


function AppTypes(props) {
  const CHART_DATATypes = [{
    name: 'gms',
    data: [props.patients.gms_type[0], props.otherpatients.other_gms_type[0]],
  }, {
    name: 'private',
    data: [props.patients.gms_type[1], props.otherpatients.other_gms_type[1]],
  }]

  const chartOptions_TypesBar = merge(BaseOptionChart(), {
    tooltip: {
      enabled: false,
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        }
      },
      y: {
        formatter: (seriesName, { seriesIndex, dataPointIndex }) => {
          if (seriesIndex === 0 && dataPointIndex === 0) {
            return 'gms prescriptions: ' + fNumber(props.prescriptions.type_gms);
          } else if (seriesIndex === 0 && dataPointIndex === 1) {
            return 'gms prescriptions: ' + fNumber(props.otherprescriptions.other_type_gms);
          } else if (seriesIndex === 1 && dataPointIndex === 0) {
            return 'private prescriptions: ' + fNumber(props.prescriptions.type_private);
          } else if (seriesIndex === 1 && dataPointIndex === 1) {
            return 'private prescriptions: ' + fNumber(props.otherprescriptions.other_type_private);
          } else {
            return fNumber(seriesName); // Use the default formatter for other cases
          }
        },
        title: {
          formatter: (seriesName) => `Number of `,
        },
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    colors: [
      '#2C6785', '#A5D8D9',
    ],
    plotOptions: {
      bar:
      {
        horizontal: false,
        dataLabels: {
          position: 'top'
        },
        columnWidth: '40%',
        distributed: false,
      },
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return fNumber(val)
      },
      style: {
        colors: ['#708090']
      },
      offsetY: -20
    },
    xaxis: {
      categories: ['Your practice', 'Other practices'],
      labels: {
        style: {
          colors: 'grey'
        }
      }
    },
    yaxis: {
      show: false,
    },
    stroke: {
      show: true,
      width: 6,
      colors: ['transparent']
    },
  });


  return (
    <Card sx={{ boxShadow: 'none', mt: 4 }}>
      <Box dir="ltr">
        <ReactApexChart type="bar" series={CHART_DATATypes} options={chartOptions_TypesBar} height={280} />
      </Box>
    </Card>
  );
}


import { connect } from "react-redux";
const mapState = state => ({
  patients: state.overview.patients,
  otherpatients: state.antibioticscomparisons.patients,

});

const mapDispatch = ({
  overview: { loadData },
  antibioticscomparisons: { getComparisonsAB },
}) => ({
  loadData: () => loadData(),
  getComparisonsAB: () => getComparisonsAB(),
});

export default connect(mapState, mapDispatch)(AppTypes);
