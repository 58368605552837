// material
import {
  Box,
  Grid,
  Container,
  Typography,
  Paper,
  Stack,
  Button,
  alpha
} from "@mui/material";
// components
import Page from "../components/Page";
import {
  AppABPiesComparison,
  ApptotalABCompare,
  ApptotalLabresultsComparison,
  AppLineABComparison,
  AppConsultPrescribeComparison,
  AppAgeOverviewComparison,
  ApptotalDurationComparison,
  ApptypesofABComparison,
  AppResistanceABComparison,
} from "../components/_dashboard/comparison";
import ComparisonsFilters from "../components/_dashboard/comparisonsfilters";
import ReactGA from "react-ga4";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import AppTypesOfABPrescription from "../components/_dashboard/comparison/AppTypesOfABPrescription";
import AppBarResistanceCompare from "src/components/_dashboard/comparison/AppBarResistanceCompare";

// ----------------------------------------------------------------------

function DashboardApp(props) {

  useEffect(() => {
    const dashNo = props.user.dashboard;
    const eventCategory = "ABCompairView " + dashNo
    const eventPage = "Antibiotic Compair Dashboard " + dashNo;

    console.log("window.location.pathname", window.location.pathname)

    ReactGA.send({
      hitType: "event",  // Instead of "pageview", we use "event" for custom tracking
      eventCategory: eventCategory,  // Define your own category
      eventAction: eventCategory,   // Or "pageview2" depending on your needs
      eventLabel: window.location.pathname,  // The current path
      title: eventPage,  // Keep the title if necessary
    });
  }, []);

  return (
    <Page title="CARA | Antibiotic Comparision Dashboard" sx={{ backgroundColor: "#fff" }}>
      <Container maxWidth="xl">
        <Box sx={{ pt: 10 }}></Box>

        <Grid container spacing={12}>
          <Grid item md={12}>
            <ComparisonsFilters />
          </Grid>

          <Grid item xs={12} sm={6} md={12}>
            <Typography
              variant="h2"
              color="secondary"
              style={{ marginTop: "55px" }}
            >
              Antibiotics
            </Typography>
            <br />
            <Button variant="contained" href="">
              Upload new data
            </Button>
            <br></br>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <ApptotalABCompare />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <ApptotalDurationComparison />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <ApptotalLabresultsComparison />
          </Grid>
        </Grid>

        <Grid container spacing={4} sx={{ borderRadius: 0 }}>
          <Grid item xs={12}>
            <AppConsultPrescribeComparison />
          </Grid>

          <Grid item xs={12} id="antibiotics">
            <Typography variant="h4" color="#22577A">
              Antibiotic Prescriptions
            </Typography>
            <Typography
              variant="subtitle3"
              color="#26734D"
              sx={{ fontWeight: "bold" }}
            >
              Green antibiotics{" "}
            </Typography>
            <Typography variant="subtitle3" color="#22577a">
              are more effective, have fewer side effects and are less likely to
              lead to resistant infections.
            </Typography>
            <br />
            <Typography
              variant="subtitle3"
              color="#cc3311"
              sx={{ fontWeight: "bold" }}
            >
              Red antibiotics{" "}
            </Typography>
            <Typography variant="subtitle3" color="#22577a">
              should not be used in general practice unless absolutely necessary.
            </Typography>
            <AppABPiesComparison />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" mt={16} color="#22577A">
              Breakdown of antibiotic prescriptions.
            </Typography>
            <Grid sx={{ marginTop: '10px', display: 'flex', flexDirection: 'row' }} xs={12}>
              <Box sx={{ mx: '4px', width: 30, height: 30, backgroundColor: "#26734D", borderRadius: 0.5 }}></Box>
              <Box sx={{ mx: '4px', width: 30, height: 30, backgroundColor: "#cc3311", borderRadius: 0.5 }}></Box>
              <Typography variant="h9">
                Your Practice
              </Typography>
            </Grid>

            <Grid sx={{ marginTop: '8px', display: 'flex', flexDirection: 'row' }} item xs={12}>
              <Box sx={{ mx: '4px', width: 30, height: 30, backgroundColor: alpha("#26734D", 0.5), borderRadius: 0.5 }}></Box>
              <Box sx={{ mx: '4px', width: 30, height: 30, backgroundColor: alpha("#cc3311", 0.5), borderRadius: 0.5 }}></Box>
              <Typography variant="h9">
                Other practices Average
              </Typography>
            </Grid>
            {/* <ApptypesofABComparison /> */}
            <AppTypesOfABPrescription />
          </Grid>


          <Grid item xs={12}>
            {/* <AppResistanceABComparison /> */}
            <AppBarResistanceCompare />
          </Grid>

          <Grid item xs={12}>
            <AppAgeOverviewComparison />
          </Grid>

          <Grid item xs={12} id="duration">
            <AppLineABComparison />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

const mapState = (state) => ({
  user: state.user,
});

const mapDispatch = ({
}) => ({
});

export default connect(mapState, mapDispatch)(DashboardApp);