import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { Box, Card, CardHeader, Grid, Stack, Typography } from "@mui/material";
// utils
import { fNumber, addToMap } from "../../../utils/formatNumber";
//
import { BaseOptionChart } from "../../charts";
import { alpha, styled } from "@mui/material/styles";
import { shadows } from "@mui/system";
import React, { useState, useEffect } from "react";

// ----------------------------------------------------------------------

function AppConversionRates(props) {
  const [refreshGraph, setRefreshGraph] = useState(false);
  const [CHART_DATA_RED, setChartDataRed] = useState([]);
  const [CHART_DATA_GREEN, setChartDataGreen] = useState([]);

  useEffect(() => {
    if (props.ab.red_resistance && props.otherab.othertypes_resistance_red) {
      let consolidatedRedMap = new Map();
      let map = addToMap(props.ab.red_resistance, 0, consolidatedRedMap);
      map = addToMap(props.otherab.othertypes_resistance_red, 1, map);

      const result = Array.from(map.entries()).map(([name, data]) => ({
        name,
        data,
      }));

      const total = result.reduce((sum, item) => sum + (item.data[0] || 0), 0);

      const others = { name: "Others", data: [0, 0] };
      const filteredResult = result
        .map((item) => ({
          ...item,
          percentage: ((item.data[0] || 0) / total) * 100,
        }))
        .filter((item) => {
          if (item.percentage < 14) {
            others.data[0] += item.data[0];
            others.data[1] += item.data[1];
            return false;
          }
          return true;
        });

      if (others.data[0] > 0) filteredResult.push(others);

      const sortedResult = filteredResult.sort((a, b) => b.data[0] - a.data[0]);
      setChartDataRed(sortedResult);
      setRefreshGraph((prev) => !prev);
    }
  }, [props.ab.red_resistance, props.otherab.othertypes_resistance_red]);

  useEffect(() => {
    if (
      props.ab.green_resistance &&
      props.otherab.othertypes_resistance_green
    ) {
      let consolidatedGreenMap = new Map();
      let map = addToMap(props.ab.green_resistance, 0, consolidatedGreenMap);
      map = addToMap(props.otherab.othertypes_resistance_green, 1, map);

      const result = Array.from(map.entries()).map(([name, data]) => ({
        name,
        data,
      }));

      const total = result.reduce((sum, item) => sum + (item.data[0] || 0), 0);

      const others = { name: "Others", data: [0, 0] };
      const filteredResult = result
        .map((item) => ({
          ...item,
          percentage: ((item.data[0] || 0) / total) * 100,
        }))
        .filter((item) => {
          if (item.percentage < 14) {
            others.data[0] += item.data[0];
            others.data[1] += item.data[1];
            return false;
          }
          return true;
        });

      if (others.data[0] > 0) filteredResult.push(others);

      const sortedResult = filteredResult.sort((a, b) => b.data[0] - a.data[0]);
      setChartDataGreen(sortedResult);
      setRefreshGraph((prev) => !prev);
    }
  }, [props.ab.green_resistance, props.otherab.othertypes_resistance_green]);

  const CHART_DATA_Consultations = [
    {
      data: [
        {
          x: "Your practice",
          y: props.ab.types_resistance_green,
        },
        {
          x: "Other practices",
          y: props.otherab.othertypes_resistance_green,
        },
      ],
    },
  ];

  const chartOptionsTypes = merge(
    {},
    {
      tooltip: {
        enabled: false,
        marker: { show: false },
        x: {
          show: true,
          // format: 'dd MMM',
          formatter: undefined,
          title: {
            formatter: (seriesName) => seriesName,
          },
        },
        y: {
          //formatter: (seriesName) => 'TOTAL'/100*fNumber(seriesName),
          formatter: function (value, opts) {
            let percent =
              opts.w.globals.seriesPercent[opts.seriesIndex][
                opts.dataPointIndex
              ];
            return percent.toFixed(0) + "%";
          },
          title: {
            formatter: (seriesName) => seriesName + ":",
          },
        },
      },
      chart: {
        type: "bar",
        stacked: true,
        stackType: "100%",
        height: 350,
      },
      colors: [
        "#26734D",
        alpha("#26734D", 0.7),
        alpha("#26734D", 0.6),
        alpha("#26734D", 0.5),
        alpha("#26734D", 0.4),
      ],

      stroke: {
        show: true,
        colors: "#cc3311",
        width: 10,
      },
      dataLabels: {
        textAnchor: "middle",
        total: {
          enabled: true,
          style: {
            fontSize: "13px",
            fontWeight: 900,
          },
        },
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          var seriesIndex = seriesIndex;
          var dataPointIndex = dataPointIndex;
          let percent = w.globals.seriesPercent[seriesIndex][dataPointIndex];
          return (
            w.config.series[seriesIndex].name + ":  " + percent.toFixed(0) + "%"
          );
        },
      },

      plotOptions: {
        bar: {
          borderRadius: 4,
          columnWidth: "70%",
          horizontal: false,
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        type: "category",
        categories: ["Your practice", "Other practices"],
        labels: {
          show: true,
        },
      },
      yaxis: {
        max: 150,
        tickAmount: 1,
        labels: {
          show: false,
          style: {
            colors: "#22577A",
          },
        },
      },
      grid: {
        show: false,
      },
    }
  );

  const chartOptionsTypes_RED = merge(
    {},
    {
      tooltip: {
        enabled: false,
        marker: { show: false },
        x: {
          show: true,
          // format: 'dd MMM',
          formatter: undefined,
          title: {
            formatter: (seriesName) => seriesName,
          },
        },
        y: {
          //formatter: (seriesName) => 'TOTAL'/100*fNumber(seriesName),
          formatter: function (value, opts) {
            let percent =
              opts.w.globals.seriesPercent[opts.seriesIndex][
                opts.dataPointIndex
              ];
            return percent.toFixed(0) + "%";
          },
          title: {
            formatter: (seriesName) => seriesName + ":",
          },
        },
      },
      chart: {
        type: "bar",
        stacked: true,
        stackType: "100%",
        height: 350,
      },
      colors: [
        "#cc3311",
        alpha("#cc3311", 0.7),
        alpha("#cc3311", 0.6),
        alpha("#cc3311", 0.5),
        alpha("#cc3311", 0.4),
      ],

      stroke: {
        show: true,
        colors: "#cc3311",
        width: 10,
      },
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          var seriesIndex = seriesIndex;
          var dataPointIndex = dataPointIndex;
          let percent = w.globals.seriesPercent[seriesIndex][dataPointIndex];
          return (
            w.config.series[seriesIndex].name + ":  " + percent.toFixed(0) + "%"
          );
        },
        // offsetX: -60,
        textAnchor: "middle",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          columnWidth: "70%",
          horizontal: false,

          //         dataLabels: {

          //                       enabled: true,
          //                       style: {
          //                         fontSize: '13px',
          //                         fontWeight: 900
          //                       },
          //                       title: {
          //                     formatter: function(seriesName, opt) {
          //             var seriesIndex = opt.seriesIndex
          //             var dataPointIndex = opt.dataPointIndex
          //                     // formatter: function(value, { seriesIndex, dataPointIndex, w }) {
          //     // return w.config.series[seriesIndex].name + ":  " + value
          //             return opt.w.config.series[seriesIndex].data[dataPointIndex].x
          // }
          //   }
          //                   },
        },
      },
      legend: {
        show: false,
        position: "bottom",
      },
      xaxis: {
        type: "category",
        categories: ["Your practice", "Other practices"],
        labels: {
          show: true,
        },
      },
      yaxis: {
        max: 150,
        tickAmount: 1,
        labels: {
          show: false,
          style: {
            colors: "#22577A",
          },
        },
      },
      grid: {
        show: false,
      },
    }
  );

  return (
    <Card sx={{ boxShadow: "none", mt: 4 }}>
      <br />
      <br />
      <br />
      <Typography variant="h6" color="#22577a">
        {" "}
        Breakdown of antibiotic resistance.
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-end"
        spacing={6}
        mt={1}
      >
        <Grid container>
          <Grid item sm={12} lg={6}>
            <Box sx={{ mx: 1, width: 500, height: 400 }} dir="ltr">
              <ReactApexChart
                type="bar"
                series={CHART_DATA_GREEN}
                options={chartOptionsTypes}
                key={refreshGraph}
              />
            </Box>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Box sx={{ mx: 1, width: 500, height: 400 }} dir="ltr">
              <ReactApexChart
                type="bar"
                series={CHART_DATA_RED}
                options={chartOptionsTypes_RED}
                key={refreshGraph}
              />
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
}
import { connect } from "react-redux";

const mapState = (state) => ({
  ab: state.antibiotics.ab,
  otherab: state.antibioticscomparisons.abcomparison,
});

const mapDispatch = ({
  antibioticscomparisons: { getComparisonsAB },
  antibiotics: { getGeneralAB },
}) => ({
  getComparisonsAB: () => getComparisonsAB(),
  getGeneralAB: () => getGeneralAB(),
});

export default connect(mapState, mapDispatch)(AppConversionRates);
