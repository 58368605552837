import { Icon } from '@iconify/react';
import { useState, useEffect } from "react";
import appleFilled from '@iconify/icons-ant-design/apple-filled';
//import doctor28Filled from '@iconify/icons-fluent/doctor-28-filled';
// material
import { alpha, styled } from '@mui/material/styles';
import { Button, Card, Typography, Box } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import ReactGA from 'react-ga4';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(2, 2),
  color: '#22577A',
  backgroundColor: '#ffffff',
  borderColor: theme.palette.primary.main,
  borderWidth: 5,
  height: 'auto',
  minHeight: 300,
  display: "flex",
  flexDirection: 'column',
  justifyContent: "center",
}));

// ----------------------------------------------------------------------
// insert AB with highest resistance as well as what the highest resistance is
const TOTAL = "56%";
const ANTIBIOTIC = "Co-amoxiclav"

function AppNewUsers(props) {

  const category = "Dashboard " + props.user.dashboard;
  const dashNo = props.user.dashboard;
  let temp = props.ab.mostApear;
  const [mostApear, setMostApear] = useState("");


  useEffect(() => {
    let anti = '';
    setMostApear('');
    let temp2 = props.ab.mostApear;
    if (temp2.length > 1) {
      for(let i =0;i<temp2.length;i++){
        if(i===0){
          anti = temp2[i];
        }else{
          anti = anti + ", " + temp2[i];
        }
      }
    } else {
      anti = temp2[0];
    }
    setMostApear(anti);
  }, [props.ab.mostApear]);

  const handleClick = () => {
    const action = "Resistace Click" + dashNo;
    ReactGA.event({
      category: category,
      action: action,
      label: "Antibiotic Dashboard " + dashNo,
      value: parseInt(dashNo)
    })

    const resistanceElement = document.getElementById('resistance');
    if (resistanceElement) {
      resistanceElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <RootStyle>
      <Box 
        sx={{ 
          height: 'auto',
          minHeight: 250,
          border: 1, 
          borderRadius: 2, 
          borderColor: "secondary", 
          display: 'flex',
          flexDirection: 'column', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          boxSizing: 'border-box'
        }}
      >
        <br />
        <Typography 
          variant="h5" 
          mt={1.5} 
          color='#aa3377' 
          style={{ fontSize: '95%' }}
        >
          {(props.ab.resistace) + '%'}
        </Typography>
        <br />
        <Typography 
          variant="subtitle2" 
          style={{ fontSize: '95%' }}
        >
          of the isolates from urine samples showed
        </Typography>
        <Typography 
          variant="subtitle2" 
          style={{ fontSize: '95%' }}
        >
          resistance to any antibiotic, mainly to:
        </Typography>
        <br />
        <Box sx={{ height: 'auto', width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Typography 
            variant={temp.length === 1 ? "h5" : "contained"} 
            color='#aa3377' 
            style={{ fontSize: '95%' }}
          >
            {(mostApear)}
          </Typography>
        </Box>
        <Button 
          fullWidth 
          style={{ boxShadow: 'none',marginTop:'5%', whiteSpace: 'nowrap'}}
          variant="contained" 
          color='secondary' 
          onClick={handleClick}
        >
          See more on resistance
        </Button>
      </Box>
    </RootStyle>
  );
}

import { connect } from "react-redux";
const mapState = state => ({
  model: state.antibiotics.generalAB,
  ab: state.antibiotics.ab,
  user: state.user
});

const mapDispatch = ({
  antibiotics: { getGeneralAB } }) => ({
    getGeneralAB: () => getGeneralAB()
  });

export default connect(mapState, mapDispatch)(AppNewUsers);
