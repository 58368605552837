import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Button,
  Chip,
  Paper,
} from "@mui/material";
// components
import { MHidden } from "../../components/@material-extend";
import Searchbar from "./Searchbar";
import FilterPopover from "./FilterPopover";
import { borders } from "@mui/system";
import { useRef, useState, useEffect } from "react";
import ReactGA from "react-ga4";
import { connect } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useHistory, useLocation, useNavigate } from "react-router-dom";
import Label from "src/components/Label";

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - 100%/6)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

function DashboardNavbar(props, { onOpenSidebar }) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [combinedFilters, setCombinedFilters] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const category = "Dashboard " + props.user.dashboard;
  const dashNo = props.user.dashboard;
  const [expanded, setExpanded] = useState(false);
  const [accordionExpand, setAccordionExpand] = useState(false);
  const [fullExpand, setFullExpand] = useState(false);
  const [iconFlipped, setIconFlipped] = useState(false);
  const [isAudit, setIsAudit] = useState(false);
  const [isMyPractice, setIsMyPractice] = useState(false);
  const [ageGroupsFilters, setAgeGroupsFilters] = useState([]);
  const [genderFilters, setGenderFilters] = useState([]);
  const [typeFilters, setTypeFilters] = useState([]);
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();

  useEffect(() => {
    if (location.pathname.includes("audit")) {
      setIsAudit(true);
    } else if (location.pathname.includes("practiceoverview")) {
      setIsMyPractice(true);
    } else {
      setIsMyPractice(false);
      setIsAudit(false);
    }
  }, [location.pathname]);

  // useEffect(() => {
  //   if (location.pathname.includes('practiceoverview')) {
  //     setIsMyPractice(true);
  //   } else {
  //     setIsMyPractice(false);
  //   }
  // }, [location.pathname]);

  const openPopover = () => {
    const action = isMyPractice ? "Filter Click_P" : "Filter Click_AB";
    const label = isMyPractice ? "Practice Dashboard" : "Antibiotic Dashboard";
    ReactGA.event({
      category: category,
      action: action,
      label: label,
      label: "Antibiotic Dashboard ",
      value: parseInt(dashNo),
    });
    setIsPopoverOpen(true);
  };

  const togglePopover = () => {
    // const action = isMyPractice ? "Filter Click_P_" + dashNo : "Filter Click_AB_" + dashNo;
    // const label = isMyPractice ? "Practice Dashboard" + dashNo : "Antibiotic Dashboard" + dashNo;
    // ReactGA.event({
    //   category: category,
    //   action: action,
    //   label: label,
    //   value: parseInt(dashNo),
    // });
    setIsPopoverOpen((prevState) => !prevState);
  };
  const closePopover = () => {
    setFullExpand(false);
    setAccordionExpand(false);
    setIsPopoverOpen(false);
  };

  const openAudit = () => {
    const action = "Audit Click_AB";
    ReactGA.event({
      category: category,
      action: action,
      label: "Antibiotic Dashboard ",
      value: parseInt(dashNo),
    });

    let path=''
    switch(dashNo){
      case "1":
        path="/dashboard/antibiotics/audit";
        break;
      case "2":
        path="/dashboard/antibiotics/audit_1";
        break;
      case "3":
        path="/dashboard/antibiotics/audit_2";
        break;
    }
    navigate(path);
    setIsAudit(true);
  };

  const openCompair = async () => {
    const action = isMyPractice ? "Compair Click_P" : "Compair Click_AB";
    const label = isMyPractice ? "Practice Dashboard" : "Antibiotic Dashboard";
    ReactGA.event({
      category: category,
      action: action,
      label: label,
      value: parseInt(dashNo),
    });

    await props.resetFilters();
    props.updateDateFilters();
    await props.compare_1();
    props.compare_2();
    props.compare_3();
    props.compare_4({});
    props.compare_5();
    props.compare_6();
    props.compare_7();
    props.pocompare_1();
    props.pocompare_2();
    props.pocompare_3();
    props.pocompare_4();
    props.pocompare_5();
    props.pocompare_6();
    props.pocompare_7();

    if (location.pathname.includes("practiceoverview")) {
      let path=''
      switch(dashNo){
        case "1":
          path="/dashboard/practiceoverview/practicecomparisons";
          break;
        case "2":
          path="/dashboard/practiceoverview/practicecomparisons_1";
          break;
        case "3":
          path="/dashboard/practiceoverview/practicecomparisons_2";
          break;
      }
      navigate(path);
    } else {
      let path=''
      switch(dashNo){
        case "1":
          path="/dashboard/antibiotics/comparison";
          break;
        case "2":
          path="/dashboard/antibiotics/comparison_1";
          break;
        case "3":
          path="/dashboard/antibiotics/comparison_2";
          break;
      }
      navigate(path);
    }
  };

  const handleAccordianChange = () => {
    setAccordionExpand(!accordionExpand);
    setExpanded(!expanded);
    setIconFlipped(!iconFlipped);
    setIsPopoverOpen(!isPopoverOpen);
  };

  const handleClearFilters = async () => {
    setSelectedOptions([]);
    setAgeGroupsFilters([]);
    setGenderFilters([]);
    setTypeFilters([]);
    setDateStart(props.user.previousYear);
    setDateEnd(props.user.lastUpload);
    await props.resetFilters();
    props.updateDateFilters();
    updateFilters();
  };

  let updateFilters = async () => {
    await props.getGeneralAB();
    props.promice_8();
    props.promice_2();
    props.promice_3_4({});
    props.promice_5_6();
    props.promice_7();
    props.getPromise_1();
    props.getPromise_2();
    props.getPromise_3();
    props.getPromise_4();
    props.getPromise_5();
    props.getPromise_6();
    props.getPromise_7();
    props.compare_1();
    props.compare_2();
    props.compare_3();
    props.compare_4({});
    props.compare_5();
    props.compare_6();
    props.compare_7();
    props.pocompare_1();
    props.pocompare_2();
    props.pocompare_3();
    props.pocompare_4();
    props.pocompare_5();
    props.pocompare_6();
    props.pocompare_7();
    props.loadData();
    props.getComparisonsAB();
  };

  return (
    <RootStyle>
      {!isAudit && (
        <>
          <ToolbarStyle>
            <Box sx={{ mx: 1, borderRadius: 0 }} dir="ltr" />
            <Grid container spacing={12}>
              <Grid item xs={2} sm={3} md={4}>
                <Button
                  style={{ fontSize: "20px" }}
                  variant="contained"
                  onClick={togglePopover}
                  fullWidth
                >
                  Filter your data
                </Button>
              </Grid>
              <Grid item xs={2} sm={3} md={4}>
                <Button
                  style={{ fontSize: "20px" }}
                  variant="contained"
                  onClick={openCompair}
                  fullWidth
                >
                  Compare your data
                </Button>
              </Grid>
              {!isMyPractice && (
                <Grid item xs={2} sm={3} md={4}>
                  <Button
                    style={{ fontSize: "20px" }}
                    variant="contained"
                    onClick={openAudit}
                    fullWidth
                  >
                    Audit
                  </Button>
                </Grid>
              )}
            </Grid>
          </ToolbarStyle>
          <FilterPopover
            isOpen={isPopoverOpen}
            onClose={() => setIsPopoverOpen(false)}
          />
        </>
      )}
    </RootStyle>
  );
}

const mapState = (state) => ({
  user: state.user,
  filters: state.filters,
});

const mapDispatch = ({
  user: { login, addDashboard },
  filters: { updateFilter, updateDateFilters, resetFilters },
  antibiotics: {
    getGeneralAB,
    promice_8,
    promice_2,
    promice_3_4,
    promice_5_6,
    promice_7,
  },
  antibioticscomparisons: {
    getComparisonsAB,
    compare_1,
    compare_2,
    compare_3,
    compare_4,
    compare_5,
    compare_6,
    compare_7,
    pocompare_1,
    pocompare_2,
    pocompare_3,
    pocompare_4,
    pocompare_5,
    pocompare_6,
    pocompare_7,
  },
  overview: {
    loadData,
    getPromise_1,
    getPromise_2,
    getPromise_3,
    getPromise_4,
    getPromise_5,
    getPromise_6,
    getPromise_7,
  },
}) => ({
  login: (payload) => login(payload),
  addDashboard: (payload) => addDashboard(payload),
  resetFilters: () => resetFilters(),
  updateFilter: (payload) => updateFilter(payload),
  updateDateFilters: () => updateDateFilters(),
  getGeneralAB: () => getGeneralAB(),
  promice_8: () => promice_8(),
  promice_2: () => promice_2(),
  promice_3_4: (payload) => promice_3_4(payload),
  promice_5_6: () => promice_5_6(),
  promice_7: () => promice_7(),
  getComparisonsAB: () => getComparisonsAB(),
  compare_1: () => compare_1(),
  compare_2: () => compare_2(),
  compare_3: () => compare_3(),
  compare_4: (payload) => compare_4(payload),
  compare_5: () => compare_5(),
  compare_6: () => compare_6(),
  compare_7: () => compare_7(),
  pocompare_1: () => pocompare_1(),
  pocompare_2: () => pocompare_2(),
  pocompare_3: () => pocompare_3(),
  pocompare_4: () => pocompare_4(),
  pocompare_5: () => pocompare_5(),
  pocompare_6: () => pocompare_6(),
  pocompare_7: () => pocompare_7(),
  getPromise_1: () => getPromise_1(),
  getPromise_2: () => getPromise_2(),
  getPromise_3: () => getPromise_3(),
  getPromise_4: () => getPromise_4(),
  getPromise_5: () => getPromise_5(),
  getPromise_6: () => getPromise_6(),
  getPromise_7: () => getPromise_7(),
  loadData: () => loadData(),
});

export default connect(mapState, mapDispatch)(DashboardNavbar);
