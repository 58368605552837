import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { useState, useEffect } from "react";
// material
import { Box, Card, CardHeader, Stack, Typography } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------



function AppPracticePrescriptions(props) {
  const [refreshGraph, setRefreshGraph] = useState(false);
  const CHART_DATA_Prescriptions = [{
    data: props.prescriptions.types
  },
  ];
  const [prescXaxis, setPrescXaxis] = useState(0);
  useEffect(() => {
    if (props.prescriptions.types && props.prescriptions.types !== undefined) {
      // If data has changed, trigger graph refresh
      setRefreshGraph(prev => !prev);
    }
  }, [props.prescriptions.types]);

  useEffect(() => {
    const data = props.prescriptions.types;
    const maxY = Math.max(...data.map((item) => item.y));
    const maxX = maxY + (maxY * 0.2);
    setPrescXaxis(maxX);
  }, [props.prescriptions.types]);

  const chartOptions_Prescriptions = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        }
      },
      y: {
        formatter: function (val) {
          const total = props.prescriptions.types.reduce((acc, cur) => acc + cur.y, 0);
          const percentage = ((val / total) * 100).toFixed(0);
          return `${percentage}%`;
        },
        title: {
          formatter: (seriesName) => `Percentage of all prescriptions: `
        }
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    colors: ['#3E8397'],
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top'
        },
      },
    },
    dataLabels: {
      formatter: function (val, opt) {
        const label_value = fNumber(val);
        return label_value;
      },
      enabled: true,
      style: {
        colors: ['#708090']
      },
      offsetX: 55
    },
    xaxis: {
      // categories: [
      //   'Diuretics',
      //   'Beta Blocking Agents',
      //   'Antipsoriatics',
      //   'Antihistamines',
      //   'Antiprotozoals',
      //   'Antibiotics',
      //   'Dopamine agonists',
      //   'Urologicals',
      //   'Opthalmologicals',
      //   ['Antiemetics and', 'Antinauseants'],
      // ],
      categories: props.prescriptions.types_categories ? props.prescriptions.types_categories : [],
      show: true,
      min: 0,
      max: prescXaxis,
      tickAmount: 5,
      labels: {
        show: false,
        formatter: function (val, opt) {
          const label_value = fNumber(val);
          return label_value;
        },
        style: {
          colors: '#808080'
        },
      }
    },
    yaxis: {
      show: true,
      labels: {
        style: {
          colors: '#22577A'
        },
      },
    },
  });





  return (
    <Card sx={{ boxShadow: 'none', mt: 4, height: 400 }} >
      <Box sx={{ mt: 4, width: 600, height: 400 }} dir="ltr">
        <ReactApexChart
          type="bar"
          series={CHART_DATA_Prescriptions}
          options={chartOptions_Prescriptions} height={364}
          key={refreshGraph} />
      </Box>
    </Card>
  );
}

import { connect } from "react-redux";
const mapState = state => ({
  prescriptions: state.overview.prescriptions
});

const mapDispatch = ({
  overview: { loadData } }) => ({
    loadData: () => loadData()
  });

export default connect(mapState, mapDispatch)(AppPracticePrescriptions);
