import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { connect } from "react-redux";
import { Grid } from "@mui/material";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  //overflow: 'hidden'
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  //overflow: 'auto',
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("md")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

const dashboard = function DashboardLayout(props) {
  const [open, setOpen] = useState(false);

  useEffect(async () => {
    await props.resetFilters();
    props.updateDateFilters();
    await props.getGeneralAB();
    props.promice_8();
    props.promice_2();
    props.promice_3_4({});
    props.promice_5_6();
    props.promice_7();
    props.getPromise_1();
    props.getPromise_2();
    props.getPromise_3();
    props.getPromise_4();
    props.getPromise_5();
    props.getPromise_6();
    props.getPromise_7();
    props.loadData();
  }, []);

  return (
    <Grid container>
      <Grid item md={2}>
        <DashboardSidebar
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
        />
      </Grid>
      <Grid item sm={12} md={10}>
        <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
        <MainStyle>
          <Outlet />
        </MainStyle>
      </Grid>
    </Grid>
  );
};

const mapState = (state) => ({});

const mapDispatch = ({
  options: { getHospitals, getPlaces, getLabs },
  overview: {
    loadData,
    getPromise_1,
    getPromise_2,
    getPromise_3,
    getPromise_4,
    getPromise_5,
    getPromise_6,
    getPromise_7,
  },
  antibiotics: {
    getGeneralAB,
    promice_8,
    promice_2,
    promice_3_4,
    promice_5_6,
    promice_7,
  },
  filters: { updateDateFilters, resetFilters },
  charts: { getModel1, getModel2 },
}) => ({
  resetFilters: () => resetFilters(),
  updateDateFilters: () => updateDateFilters(),
  getPlaces: () => getPlaces(),
  getLabs: () => getLabs(),
  getModel1: () => getModel1(),
  getModel2: () => getModel2(),
  // getPractice:()=>getPractice(),
  getPromise_1: () => getPromise_1(),
  getPromise_2: () => getPromise_2(),
  getPromise_3: () => getPromise_3(),
  getPromise_4: () => getPromise_4(),
  getPromise_5: () => getPromise_5(),
  getPromise_6: () => getPromise_6(),
  getPromise_7: () => getPromise_7(),
  loadData: () => loadData(),
  getGeneralAB: () => getGeneralAB(),
  promice_8: () => promice_8(),
  promice_2: () => promice_2(),
  promice_3_4: (payload) => promice_3_4(payload),
  promice_5_6: () => promice_5_6(),
  promice_7: () => promice_7(),
  //loadDataGender:()=>loadDataGender(),
  // loadDataAge:()=> loadDataAge(),
  // loadDataType:()=> loadDataType(),
});

export default connect(mapState, mapDispatch)(dashboard);
