import { Icon } from '@iconify/react';
// material
import { alpha, styled } from '@mui/material/styles';
import { Button, Card, Typography, Box } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(2, 2),
  color: '#22577A',
  backgroundColor: '#ffffff',
  style: { borderColor: '#000000', borderWidth: 10 },
  height: 250,
  display: "flex",
  flexDirection: 'column',
  justifyContent: "center",
}));


function AppNewUsers(props) {
  return (
    <RootStyle>
      <Box sx={{ height: 250, border: 1, borderRadius: 2, borderColor: "secondary" }}>
        <br />
        <Typography variant="h5" mt={1.5} color='#aa3377'>{(props.ab.resistace) + '%'}</Typography>
        <Typography variant="subtitle2" >
          of the isolates from urine samples showed resistance to any antibiotic.
        </Typography>
        <br></br>
        <Typography variant="subtitle2">
          Other practices average:
        </Typography>
        <Typography variant="h5" color='#aa3377'>{(props.generalABcomparison.otherperc_resistance_ab) + '%'}</Typography>
        <br>
        </br>
        <Button fullWidth style={{ whiteSpace: 'nowrap' }} variant="contained" color='secondary' href='#resistance'>See more on resistance</Button>
      </Box>
    </RootStyle>
  );
}

import { connect } from "react-redux";
const mapState = state => ({
  generalAB: state.antibiotics.generalAB,
  ab: state.antibiotics.ab,
  generalABcomparison: state.antibioticscomparisons.generalABcomparison
});

const mapDispatch = ({
  antibioticscomparisons: { getComparisonsAB },
  antibiotics: { getGeneralAB }
}) => ({
  getComparisonsAB: () => getComparisonsAB(),
  getGeneralAB: () => getGeneralAB(),
});

export default connect(mapState, mapDispatch)(AppNewUsers);
