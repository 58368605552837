import { merge } from "lodash";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
// material
import { useTheme, styled } from "@mui/material/styles";
import { Card, CardHeader, Stack, Box, alpha, Typography } from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
//
import { BaseOptionChart } from "../../charts";

// ----------------------------------------------------------------------

function AppCurrentVisits(props) {
  const [chartColors, setChartColors] = useState([]);
  const [maxPo, setMaxPo] = useState(0);

  useEffect(() => {
    if (props.patients.gender_categories.length === 1) {
      if (props.patients.gender_categories[0] == "Male")
        setChartColors(["#4f9ea9"]);
      else setChartColors(["#ff8533"]);
    } else {
      setChartColors(["#ff8533", "#4f9ea9"]);
    }
  }, [props.patients.gender_categories]);

  useEffect(() => {
    const grandMax = Math.max(
      ...props.patients.gender_patients,
      ...props.otherPatients.other_gender_patients
    );
    const maxX = grandMax + grandMax * 0.2;
    setMaxPo(maxX);
  }, [
    props.patients.gender_patients,
    props.otherPatients.other_gender_patients,
  ]);

  const mixedGender = [
    {
      data: props.patients.gender_patients,
    },
  ];

  const mixedGenderFilteredP = [
    {
      data: props.otherPatients.other_gender_patients,
    },
  ];
  
  const CHART_DATATypes = [{
    name: 'male',
    data: [props.patients.gender_patients[0], props.otherPatients.other_gender_patients ? props.otherPatients.other_gender_patients[0] : 0],
  }, {
    name: 'female',
    data: [props.patients.gender_patients[1], props.otherPatients.other_gender_patients ? props.otherPatients.other_gender_patients[1] : 0],
  }]

  const chartOptions_TypesBar = merge(BaseOptionChart(), {
    tooltip: {
      enabled: false,
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        }
      },
      y: {
        formatter: (seriesName, { seriesIndex, dataPointIndex }) => {
          if (seriesIndex === 0 && dataPointIndex === 0) {
            return 'male: ' + fNumber(props.patients.gender_patients);
          } else if (seriesIndex === 0 && dataPointIndex === 1) {
            return 'male: ' + fNumber(props.otherpatients.other_gender_patients);
          } else if (seriesIndex === 1 && dataPointIndex === 0) {
            return 'female: ' + fNumber(props.patients.gender_patients);
          } else if (seriesIndex === 1 && dataPointIndex === 1) {
            return 'female: ' + fNumber(props.otherpatients.other_gender_patients);
          } else {
            return fNumber(seriesName); // Use the default formatter for other cases
          }
        },
        title: {
          formatter: (seriesName) => `Number of `,
        },
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    colors: [
      "#ff8533", "#4f9ea9",
    ],
    plotOptions: {
      bar:
      {
        horizontal: false,
        dataLabels: {
          position: 'top'
        },
        columnWidth: '40%',
        distributed: false,
      },
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return fNumber(val)
      },
      style: {
        colors: ['#708090']
      },
      offsetY: -20
    },
    xaxis: {
      categories: ['Your practice', 'Other practices'],
      labels: {
        style: {
          colors: 'grey'
        }
      }
    },
    yaxis: {
      show: false,
    },
    stroke: {
      show: true,
      width: 6,
      colors: ['transparent']
    },
  });

  return (
    <Card sx={{ boxShadow: "none", mt: 4 }}>
      <Box dir="ltr">
        <ReactApexChart type="bar" series={CHART_DATATypes} options={chartOptions_TypesBar} height={280} />
      </Box>
    </Card>
  );
}

import { connect } from "react-redux";
const mapState = (state) => ({
  patients: state.overview.patients,
  otherPatients: state.antibioticscomparisons.patients,
});

const mapDispatch = ({
  overview: { loadData },
  antibioticscomparisons: { getComparisonsAB },
}) => ({
  loadData: () => loadData(),
  getComparisonsAB: () => getComparisonsAB(),
});

export default connect(mapState, mapDispatch)(AppCurrentVisits);
