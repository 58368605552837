import { useNavigate } from "react-router-dom";
import { isJwtExpired, timeToExpire } from '../components/authentication/checkJWT';
import config from '../config.json';
import { getDataWithBody } from './auxilarFunctions';

let observeJWT = (token, dispatch, reference) => {
  dispatch.session.updateShowAlert(false);
  if (reference) {
    clearInterval(reference);
  }
  dispatch.session.updateTimeToExpire(60);
  let interval = setInterval(() => {
    let tte = timeToExpire(token)
    dispatch.session.updateTimeToExpire(tte);
    if (tte > 0 && tte < 30) {
      dispatch.session.updateShowAlert(true);
    }
    if (tte < 0) {
      dispatch.session.updateShowAlert(false);
      dispatch.user.logout();
    }
  }, 10000);
  dispatch.session.updateTimerReference(interval);
}

async function postData(url = '', data = {}, jwt) {
  // Default options are marked with *
  let headers;
  if (jwt) {
    headers = {
      'Authorization': 'Bearer ' + jwt,
      'Content-Type': 'application/json'
    }
  }
  else {
    headers = {
      'Content-Type': 'application/json'
    }
  }
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: headers,
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });

  const message = await response.json();
  return message; // parses JSON response into native JavaScript objects
}

// count model
export const user = {
  state: {
    id: null,
    username: "Strandhill Health Centre",
    email: null,
    login: null,
    access_token: null,
    profileCompleted: false,
    dashboard: null,
    lastUpload: null,
    previousYear: null,
  },

  reducers: {
    updateToken(state, payload) {
      return {
        ...state,
        id: payload.user.id,
        username: payload.user.username,
        email: payload.user.email,
        access_token: payload.access_token,
        login: true,
        profileCompleted: payload.user.profileCompleted,
        dashboard: payload.user.dashboard
      }
    },
    loginUser(state, payload) {
      // Store user data in localStorage
      localStorage.setItem('user', JSON.stringify(payload));
      return {
        ...state,
        id: payload.user.id,
        username: payload.user.username,
        email: payload.user.email,
        access_token: payload.access_token,
        login: true,
        profileCompleted: payload.user.profileCompleted,
        dashboard: payload.user.dashboard,
        lastUpload: payload.user.lastUpload,
        previousYear: payload.user.previousYear,
      }
    },
    logoutUser(state) {
      localStorage.removeItem("user"); // clear persisted data
      return { ...state, login: null };
    },
    profileUpdated(state, payload) {
      return {
        ...state,
        profileCompleted: true,
      }
    },
    dashboardUpdated(state, payload) {
      return {
        ...state,
        dashboard: payload,
      }
    },
    clearUser(state, payload) {
      return {
        ...state,
        id: null,
        username: null,
        email: null,
        access_token: null,
        login: false,
        dashboard: null
      }
    },

  },
  effects: (dispatch) => ({

    async login(payload, state) {
      const message = await postData(config.url + '/user/login', { email: payload.email, password: payload.password })
      if (message.message == "Login Successful") {
        await dispatch.user.loginUser(message);
        if (message.user.practiceDetails) {
          await dispatch.practice.update(message.user.practiceDetails);
        }
        //observeJWT(data.access_token, dispatch, state.session.timerReference);
      };
      return (message.message);
    },
    async login2(payload, state) {
      const message = await postData(config.url + '/user/login', { email: "hamad@gmail.com", password: '123456' })
      if (message.message == "Login Successful") {
        await dispatch.user.loginUser(message);
        if (message.user.practiceDetails) {
          await dispatch.practice.update(message.user.practiceDetails);
        }
        //observeJWT(data.access_token, dispatch, state.session.timerReference);
      };
      return (message.message);
    },
    async register(payload, state) {
      const message = await postData(config.url + '/user/signup', { username: payload.username, email: payload.email, password: payload.password })
      if (!message.successMessage) {
        return ("Not registered")
      } else {
        return ("Registered");
      }
    },
    async verifyOTP(payload = {}, state) {
      const headers = {
        'Content-Type': 'application/json'
      }
      const message = await fetch(config.url + '/user/verify', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: headers,
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(payload) // body data type must match "Content-Type" header
      })
      if (message.status !== 200) {
        if (message.status === 400) {
          const response = await message.json();
          return (response.error);
        }
      } else {
        const response = await message.json();
        return (response.message);
      }
    },
    async resendOTP(payload, state) {
      const message = await postData(config.url + '/user/resendOTP', payload);
      return;
    },
    async addDashboard(payload, state) {
      const message = await postData(config.url + '/user/addDashboard', payload, state.user.access_token);
      if (message.message && message.message === "dashboard saved") {
        this.dashboardUpdated(payload.dashboard);
      }
      return;
    },
    async resetPassword(payload, state) {
      const message = await postData(config.url + '/user/newPassword', payload);
      return message.message;
    },
    async refreshToken(payload, state) {
      return postData(config.url + '/user/refresh_token', {})
        .then(data => {

          this.updateToken(data);

          dispatch.practice.updateDetails(data.user.practiceDetails);
          observeJWT(data.access_token, dispatch, state.session.timerReference);
          return (data.access_token);
        })
        .catch(err => console.log(err))
    },
    async logout(payload, state) {
      return postData(config.url + '/user/logout', {})
        .then(async data => {
          clearInterval(state.session.timerReference);
          await dispatch.user.logoutUser();
          await dispatch.user.clearUser();
        });
    },
    async checkUsername(payload, state) {

      const response = await postData(config.url + '/user/checkUsername', payload);

      return (response.message); // parses JSON response into native JavaScript objects
    },
    async verify(payload, state) {
      return postData(config.url + '/user/verify', { token: payload.token, email: payload.email }, payload.jwttoken)
        .then(data => {
          //dispatch.user.loginUser(data);
          //observeJWT(data.access_token, dispatch, state.session.timerReference);
        });
    },
  })
};