import { Navigate, useRoutes, useParams } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import PracticeLayout from './layouts/PracticeLayout';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import NathalyLayout from './layouts/nathalyLayout';
//
import Login from './pages/Login';
import Login2 from './pages/Login2';
import Register from './pages/Register';
import Details from './components/authentication/register/PracticeSetUp';
import Verify from './pages/Verify';
import DashboardApp from './pages/DashboardApp';
import AntibioticsDashboard from './pages/AntibioticsDashboard'
import AntibioticsDashboard_1 from './pages/AntibioticsDashboard_1'
import AntibioticsDashboard_2 from './pages/AntibioticsDashboard_2'
import Products from './pages/Products';
import DashButton from './pages/dashButton';
import Blog from './pages/Blog';
import User from './pages/User';
import NotFound from './pages/Page404';
import { connect } from "react-redux";

import PracticeOverview from './pages/PracticeOverview';
import PracticeComparison from './pages/PracticeComparison';
import Audits from './pages/Audits';
import Auditreport1 from './pages/Auditreport1';
import Auditreport1b from './pages/Auditreport1b';
import Auditreport1c from './pages/Auditreport1c';
import Auditreport2 from './pages/Auditreport2';
import Auditreport2b from './pages/Auditreport2b';
import Auditreport2c from './pages/Auditreport2c';
import Auditreport3 from './pages/Auditreport3';
import Auditreport3b from './pages/Auditreport3b';
import Auditreport3c from './pages/Auditreport3c';
import {
  StaticsPAges
} from './components/_dashboard/audit';
import Comparison from './pages/Comparison';
import SelectDashboard from './pages/SelectDashboard';
import Nathaly1 from './pages/Nathaly1';
import Nathaly2 from './pages/Nathaly2';
import RegistrationDetailes from './pages/RegistrationDetailes';
import InterOTP from './pages/InterOTP';

import EnterNewEmail from './pages/EnterNewEmail';
import EnterEmail from './pages/EnterEmail';

// ----------------------------------------------------------------------




const router = function Router(props) {

  const ProtectedRoute = ({ children }) => {
    const storedUser = localStorage.getItem('user');
    const isAuthenticated = props.user.login || (storedUser && JSON.parse(storedUser).access_token);
    
    if (!isAuthenticated) {
      return <Navigate to="/login" />;
    }

    return children;
  };


  return useRoutes([
    {
      path: '/dashboard',
      element: <ProtectedRoute><DashboardLayout /></ProtectedRoute>,
      children: [
        { element: <Navigate to="/dashboard/antibiotics" replace /> },
        // { path: 'antibiotics', element: props.user.profileCompleted ? <AntibioticsDashboard /> : <Details /> },
        // { path: 'antibiotics_1', element: props.user.profileCompleted ? <AntibioticsDashboard_1 /> : <Details /> },
        // { path: 'antibiotics_2', element: props.user.profileCompleted ? <AntibioticsDashboard_2 /> : <Details /> },

        { path: 'antibiotics', element: <AntibioticsDashboard /> },
        { path: 'antibiotics_1', element: <AntibioticsDashboard_1 /> },
        { path: 'antibiotics_2', element: <AntibioticsDashboard_2 /> },
        { path: 'user', element: <User /> },
        { path: 'antibiotics/audit', element: <Audits /> },
        { path: 'antibiotics/audit/report1', element: <Auditreport1 /> },
        { path: 'antibiotics/audit/report1b', element: <Auditreport1b /> },
        { path: 'antibiotics/audit/report1c', element: <Auditreport1c /> },
        { path: 'antibiotics/audit/report2', element: <Auditreport2 /> },
        { path: 'antibiotics/audit/report2b', element: <Auditreport2b /> },
        { path: 'antibiotics/audit/report2c', element: <Auditreport2c /> },
        { path: 'antibiotics/audit/report3', element: <Auditreport3 /> },
        { path: 'antibiotics/audit/report3b', element: <Auditreport3b /> },
        { path: 'antibiotics/audit/report3c', element: <Auditreport3c /> },
        { path: 'antibiotics/comparison', element: <Comparison /> },
        { path: 'antibiotics/audit_1', element: <Audits /> },
        { path: 'antibiotics/audit/report1_1', element: <Auditreport1 /> },
        { path: 'antibiotics/audit/report1b_1', element: <Auditreport1b /> },
        { path: 'antibiotics/audit/report1c_1', element: <Auditreport1c /> },
        { path: 'antibiotics/audit/report2_1', element: <Auditreport2 /> },
        { path: 'antibiotics/audit/report2b_1', element: <Auditreport2b /> },
        { path: 'antibiotics/audit/report2c_1', element: <Auditreport2c /> },
        { path: 'antibiotics/audit/report3_1', element: <Auditreport3 /> },
        { path: 'antibiotics/audit/report3b_1', element: <Auditreport3b /> },
        { path: 'antibiotics/audit/report3c_1', element: <Auditreport3c /> },
        { path: 'antibiotics/comparison_1', element: <Comparison /> },
        { path: 'antibiotics/audit_2', element: <Audits /> },
        { path: 'antibiotics/audit/report1_2', element: <Auditreport1 /> },
        { path: 'antibiotics/audit/report1b_2', element: <Auditreport1b /> },
        { path: 'antibiotics/audit/report1c_2', element: <Auditreport1c /> },
        { path: 'antibiotics/audit/report2_2', element: <Auditreport2 /> },
        { path: 'antibiotics/audit/report2b_2', element: <Auditreport2b /> },
        { path: 'antibiotics/audit/report2c_2', element: <Auditreport2c /> },
        { path: 'antibiotics/audit/report3_2', element: <Auditreport3 /> },
        { path: 'antibiotics/audit/report3b_2', element: <Auditreport3b /> },
        { path: 'antibiotics/audit/report3c_2', element: <Auditreport3c /> },
        { path: 'antibiotics/comparison_2', element: <Comparison /> },

      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: '*', element: <Navigate to="/404" /> },
        { path: 'dashButton', element: <DashButton /> },
      ]
    },
    {
      path: '/dashboard',
      element: <NathalyLayout />,
      children: [
        { path: 'nathalyone', element: <Nathaly1 /> },
        { path: 'nathalytwo', element: <Nathaly2 /> },
        { path: 'selectdashboard', element: <SelectDashboard /> },
      ]
    },
    {
      path: '/dashboard',
      element: <ProtectedRoute><PracticeLayout /></ProtectedRoute>,
      children: [
        { path: 'practiceoverview', element: <PracticeOverview /> },
        { path: 'practiceoverview/practicecomparisons', element: <PracticeComparison /> },
        { path: 'practiceoverview_1', element: <PracticeOverview /> },
        { path: 'practiceoverview/practicecomparisons_1', element: <PracticeComparison /> },
        { path: 'practiceoverview_2', element: <PracticeOverview /> },
        { path: 'practiceoverview/practicecomparisons_2', element: <PracticeComparison /> },
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'verify/:token/:email/:jwttoken', element: <Verify /> },
        { path: 'nathalyone', element: <Nathaly1 /> },
        { path: 'nathalytwo', element: <Nathaly2 /> },

        { path: 'interOTP', element: <InterOTP /> },
        { path: 'practiceDetails', element: <RegistrationDetailes /> },


        { path: 'forgetPassword', element: <EnterNewEmail /> },
        { path: 'verifyMail', element: <EnterEmail /> },

      ]
    },
    { path: 'antibiotics/audit/staticspages', element: <StaticsPAges /> },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

const mapState = state => ({
  user: state.user
});

const mapDispatch = ({
  user: { login } }) => ({
    login: (payload) => login(payload)
  });


export default connect(mapState, mapDispatch)(router);
