import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography, CircularProgress } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import AuthSocial from '../components/authentication/AuthSocial';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { connect } from "react-redux";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 400,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(15, 0)
}));

// ----------------------------------------------------------------------

function Login2(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.user.login && props.user.login !== undefined) {
      let randomValue;
      if (props.user.dashboard && props.user.dashboard !== undefined) {
        randomValue = props.user.dashboard;
      } else {
        randomValue = Math.floor(Math.random() * 3) + 1;
        const payload2 = {
          dashboard: randomValue
        }
        props.addDashboard(payload2);
      }
      navigate('/dashboard/antibiotics', { replace: true });
    } else {
      setLoading(false);
    }
  }, [props.user]);

  const response = props.login({});

  if (loading) {
    return (
      <Container>
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
      </Container>
    );
  }

  return (
    <RootStyle>
      <AuthLayout>
        <ContentStyle>
          <Stack spacing={3}>
            <Typography variant="h4" gutterBottom>
              Login
            </Typography>
            <LoginForm />
            <MHidden width="smDown">
              <AuthSocial />
            </MHidden>
            <Typography variant="body2" align="center">
              Don’t have an account?{' '}
              <Link variant="subtitle2" component={RouterLink} to="/register">
                Get started
              </Link>
            </Typography>
          </Stack>
        </ContentStyle>
      </AuthLayout>
    </RootStyle>
  );
}

const mapState = state => ({
  user: state.user
});

const mapDispatch = ({
  user: { login, addDashboard } }) => ({
    login: (payload) => login(payload),
    addDashboard: (payload) => addDashboard(payload)
  });

export default connect(mapState, mapDispatch)(Login2);
