import { Icon } from '@iconify/react';
import androidFilled from '@iconify/icons-ant-design/android-filled';
// material
import { alpha, styled } from '@mui/material/styles';
import { Button, Box, Card, Typography } from '@mui/material';
import ReactGA from 'react-ga4';
// utils
import { fNumber, fShortenNumber } from '../../../utils/formatNumber';
import checkOutlined from '@iconify/icons-ant-design/check-outlined';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(2, 2),
  color: '#22577A',
  backgroundColor: '#ffffff',
  borderColor: theme.palette.primary.main,
  borderWidth: 5,
  height: 'auto',
  minHeight: 300,
  display: "flex",
  flexDirection: 'column',
}));

// ----------------------------------------------------------------------
// calculate total number of AB prescriptions of practice and all practices average for 12 months prior to last data upload.

function AppWeeklySales(props) {

  const category = "Dashboard " + props.user.dashboard;
  const dashNo = props.user.dashboard;

  const handleClick = () => {
    const resistanceElement = document.getElementById('antibiotics');
    if (resistanceElement) {
      resistanceElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <RootStyle>
      <Box
        sx={{
          height: 'auto',
          minHeight: 250,
          border: 1,
          borderRadius: 2,
          borderColor: "secondary",
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxSizing: 'border-box',
          flexGrow: 1,
        }}
      >
        <br />
        <Typography
          variant="h5"
          mt={1.5}
          color='#aa3377'
          style={{ fontSize: '95%' }}
        >
          {fNumber(props.model.total ? props.model.total : '0')}
        </Typography>
        <Typography variant="subtitle2" style={{ fontSize: '95%' }}>
          Number of antibiotics prescribed
        </Typography>
        <br />
        <Typography variant="subtitle2" style={{ fontSize: '95%' }}>
          All practices average:
        </Typography>
        <Typography
          variant="h5"
          color='#aa3377'
          style={{ fontSize: '95%' }}
        >
          {fNumber(props.model.average ? props.model.average : '0')}
        </Typography>
        <Button
          fullWidth
          style={{ boxShadow: 'none', marginTop: '10%', whiteSpace: 'nowrap' }}
          variant="contained"
          color='secondary'
          onClick={handleClick}
        >
          See more on antibiotics
        </Button>
      </Box>
    </RootStyle>
  );
}

import { connect } from "react-redux";
const mapState = state => ({
  model: state.antibiotics.generalAB,
  user: state.user
});

const mapDispatch = ({
  antibiotics: { getGeneralAB }
}) => ({
  getGeneralAB: () => getGeneralAB()
});

export default connect(mapState, mapDispatch)(AppWeeklySales);