import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
import { Card, Box, Input } from "@mui/material";
import { fNumber } from "../../../utils/formatNumber";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { BaseOptionChart } from "../../charts";

// ----------------------------------------------------------------------
//data for 3months: Dec 22, Jan 23 and Feb 23 compared with data for Dec 21, Jan 22 and Feb 22
const CHART_DATA_Line = [
  {
    name: "Green Antibiotics prescribed in 2022",
    data: [280, 290, 330, 150, 200, 133, 33, 67, 111, 187, 230, 255],
  },
  {
    name: "Red Antibiotics prescribed in 2022",
    data: [120, 110, 140, 180, 170, 130, 13, 55, 55, 69, 112, 198],
  },
  {
    name: "Green Antibiotics prescribed in 2021",
    data: [380, 190, 530, 50, 280, 163, 3, 78, 141, 157, 239, 355],
  },
  {
    name: "Red Antibiotics prescribed in 2021",
    data: [140, 90, 135, 150, 120, 150, 65, 42, 42, 60, 12, 98],
  },
];

function AppLine(props) {
  const [chartData, setChartData] = useState([]);
  const [dateRange1, setDateRange1] = useState([]);
  const [dateEnd, setDateEnd] = useState(props.lastUpdata);

  useEffect(() => {
    const currentDate = new Date(dateEnd);
    const end1 = new Date(currentDate);
    end1.setMonth(end1.getMonth() - 3);
  
     const dateRange = [];
    let currentDatePointer = new Date(end1);
  
    while (currentDatePointer <= currentDate) {
      dateRange.push(currentDatePointer.toISOString().split("T")[0]); // Use ISO format to avoid time zone issues
      currentDatePointer.setDate(currentDatePointer.getDate() + 1);
    }
    setDateRange1(dateRange);

    const data = props.audit3;
  
    const processedData = data.flatMap((yearData) =>
      Object.entries(yearData).map(([year, colors]) => {
        const greenData = colors["Green"];
        const redData = colors["Red"];
  
        const greenSeries = [];
        const redSeries = [];
  
        dateRange.forEach((date) => {
          const [yearStr, monthStr, dayStr] = date.split("-");
          const month = parseInt(monthStr);
          const day = parseInt(dayStr);
  
          greenData[month]
            ? greenData[month][day]
              ? greenSeries.push(greenData[month][day])
              : greenSeries.push(0)
            : greenSeries.push(0);

          redData[month]
            ? redData[month][day]
              ? redSeries.push(redData[month][day])
              : redSeries.push(0)
            : redSeries.push(0);
        });
  
        return [{"name":`Green Antibiotics prescribed in ${year}`,data: greenSeries},
                {"name":`Red Antibiotics prescribed in ${year}`,data: redSeries}];
      })
    );
  
    let furtherProceeded = processedData.flat();

    setChartData(furtherProceeded);
  }, []);
  

  // useEffect(() => {
  //   const data = props.audit3;
  //   console.log("data :", data);
  //   const processedData = data.flatMap((yearData) =>
  //     Object.entries(yearData).map(([year, colors]) => {
  //       const series = Object.entries(colors).map(([color, months]) => ({
  //         name: `${color} Antibiotics prescribed in ${year}`,
  //         data: Object.values(months).flatMap((days) => Object.values(days)),
  //       }));
  //       return series;
  //     })
  //   );
  //   let furtherProceeded = processedData.flat();
  //   console.log("furtherProceeded :", furtherProceeded);
  //   setChartData(furtherProceeded);
  // }, []);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const startMonth = 2; // Start from February
  const endMonth = 5; // End at May
  const categories = [];

  dateRange1.forEach((data,index ) => {

    const [monthStr, dayStr, yearStr] = data.split("-");
    const year = parseInt(yearStr);
    const month = parseInt(monthStr);
    const day = parseInt(dayStr);
    categories.push(data);
  });

  const chartOptionsLine = merge(BaseOptionChart(), {
    tooltip: {
      shared: true,
      intersect: false,
      marker: { show: false },
      x: {
        show: false,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => seriesName + ": ",
        },
      },
    },
    chart: {
      type: 'area',
      stacked: false,
      height: 364,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: 'zoom'
      }
    },
    colors: ["#26734D", "#cc3311", "#b3e6cc", "#f9c4b8"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      borderColor: "#F5F5F5",
    },
    markers: {
      size: 0,
    },
    xaxis: {
      categories: categories,
      type: 'datetime',
    },
    yaxis: {
      min: 0,
      tickAmount: 7,
      forceNiceScale: true,
    },
    legend: {
      show: true,
      position: "bottom",
      offsetY: 10,
      formatter: function(seriesName, opts) {
        // Extract first word and year
        const firstWord = seriesName.split(' ')[0];
        const yearMatch = seriesName.match(/\d{4}$/);
        return `${firstWord} ${yearMatch ? yearMatch[0] : ''}`;
      }
    },
    // annotations: {
    //   xaxis: [
    //     {
    //       x: 'Sept',
    //       borderColor: '#aa3377',
    //       label: {
    //         style: {
    //           color: '#aa3377',
    //         },
    //         text: 'Audit Start'
    //       }
    //     }
    //   ]
    // }
  });

  return (
    <Card sx={{ boxShadow: "none" }}>
      <br />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart
          type="line"
          series={chartData}
          options={chartOptionsLine}
          height={364}
        />
      </Box>
      <Box sx={{ mx: 5, mt: 10, border: 1, p: 5 }}>
        <Input fullWidth multiline rows={8} defaultValue="Your analysis here" />
      </Box>
    </Card>
  );
}
const mapState = (state) => ({
  audit3: state.audit.audit3,
  lastUpdata: state.user.lastUpload,
});

const mapDispatch = ({ antibiotics: { getGeneralAB } }) => ({
  getGeneralAB: () => getGeneralAB(),
});

export default connect(mapState, mapDispatch)(AppLine);
