import { Icon } from '@iconify/react';
import appleFilled from '@iconify/icons-ant-design/apple-filled';
// material
import { alpha, styled } from '@mui/material/styles';
import { Button, Card, Typography, Box } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import ReactGA from 'react-ga4';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(2, 2),
  color: '#22577A',
  backgroundColor: '#ffffff',
  borderColor: theme.palette.primary.main,
  borderWidth: 5,
  height: 'auto',
  minHeight: 300,
  display: "flex",
  flexDirection: 'column',
  justifyContent: "center",
}));

// ----------------------------------------------------------------------
// AB1Percentage to take percentage of all patients receiving one antibiotic in previous 12months
const AB1Percentage = "27%";
// ABMore to take percentage of all patients receiving antibiotics for more than 3 days
const ABMorePercentage = "15%";

function AppNewUsers(props) {

  const category = "Dashboard " + props.user.dashboard;
  const dashNo = props.user.dashboard;

  const handleClick = () => {
    const resistanceElement = document.getElementById('duration');
    if (resistanceElement) {
      resistanceElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <RootStyle>
      <Box
        sx={{
          height: 'auto',
          minHeight: 250,
          border: 1,
          borderRadius: 2,
          borderColor: "secondary",
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxSizing: 'border-box',
        }}
      >
        <br />
        <Typography
          variant="h5"
          mt={1.5}
          color='#aa3377'
          style={{ fontSize: '95%' }}
        >
          {(props.model.perc_12_month) + '%'}
        </Typography>
        <Typography variant="subtitle2" style={{ fontSize: '95%' }}>
          of your patients are prescribed an antibiotic at least once.
        </Typography>
        <br />
        <Typography variant="subtitle2" style={{ fontSize: '95%' }}>
          Percentage of these patients being prescribed an antibiotic for longer than 7 days:
        </Typography>
        <Typography
          variant="h5"
          color='#aa3377'
          style={{ fontSize: '95%' }}
        >
          {(props.model.perc_12_month_3_days) + '%'}
        </Typography>
        <Button
          fullWidth
          style={{ boxShadow: 'none', marginTop: '5%', whiteSpace: 'nowrap' }}
          variant="contained"
          color='secondary'
          onClick={handleClick}
        >
          See more on duration
        </Button>
      </Box>
    </RootStyle>
  );
}

import { connect } from "react-redux";
const mapState = state => ({
  model: state.antibiotics.generalAB,
  user: state.user
});

const mapDispatch = ({
  antibiotics: { getGeneralAB }
}) => ({
  getGeneralAB: () => getGeneralAB()
});

export default connect(mapState, mapDispatch)(AppNewUsers);
